@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "BrandonPrintedTwo";
  src: local("BrandonPrintedTwo"),
    url("./fonts/BrandonPrintedTwo.ttf") format("truetype");
  font-weight: bold;
}

.font-face-gm {
  font-family: "BrandonPrintedTwo";
}


:root {
  --max-width: 1100px;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}


html,
body {
  max-width: 100vw;
  overflow-x: hidden;
}


body {
  width: 100vw;
  height: 100vh;
  color: white;
  background: rgb(2, 132, 199);
  background: radial-gradient(
    circle,
    rgba(2, 132, 199, 1) 0%,
    rgba(2, 132, 199, 1) 7%,
    rgba(23, 37, 84, 1) 100%
  );
}

